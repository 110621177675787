<template>
  <div>
    <div v-if="!formSubmitted">
      <p class="mb-4 text-xl">
        <strong class="font-extrabold">Our experts</strong> can provide you with <br class="hidden md:inline" />
        <strong class="font-extrabold">help to find you better offers.</strong>
      </p>

      <img
        v-show="submitInProgress"
        class="py-16 mx-auto"
        width="231"
        height="231"
        src="/images/ripple-loading.gif"
        alt="loading"
      />

      <form
        v-if="!_isEmpty(formModel)"
        v-show="!submitInProgress"
        class="flex flex-wrap -mx-2"
        @submit.prevent="handleSubmitForm"
      >
        <div
          v-for="(item, itemIndex) in formData"
          :key="itemIndex"
          class="px-2 mb-4 last:mb-6"
          :class="item.componentClass"
        >
          <component
            :is="getKey(item.component, componentMap)"
            v-bind="item.props"
            v-model="formModel[item.props.name]"
            class="lg:text-base"
            :data-arr="dataArr[item.props.name]"
            :disabled="dataArr[item.props.name]?.length===0"
            :error-message="validation[item.props.name]?.errorMessage"
            :is-success="validation[item.props.name]?.isSuccess"
            @input="handleValidateFormItem(item.props.name, formModel[item.props.name])"
          />
        </div>
        <div class="flex flex-wrap md:flex-nowrap gap-2 px-2 justify-items-stretch w-full bg-white">
          <div class="grow order-last md:order-first">
            <slot name="cancelButton" />
          </div>
          <div class="grow md:grow-0 md:ml-auto w-full md:w-auto">
            <atoms-button
              type="submit"
              class="lg:px-8 w-full justify-center"
              :size="isStandalonePage ? 'large' : 'medium'"
              :full="devices.isMobile.value"
              :tracking-additional-params="trackingAdditionalParams"
            >
              SCHEDULE CALLBACK
            </atoms-button>
          </div>
        </div>

        <!-- DISCLAIMER  -->
        <div class="mt-4 text-xs text-gray-80">
          By clicking ‘Schedule Callback', I acknowledge that I have read and agree to the
          <AtomsLink
            class="font-bold underline"
            target="_blank"
            to="/terms-of-use/"
          >
            Terms of Use
          </AtomsLink>, the
          <AtomsLink
            class="font-bold underline"
            target="_blank"
            to="/privacy-policy/"
          >
            Privacy Policy
          </AtomsLink> and the
          <atoms-popover-v2
            class="inline-flex"
            theme="default"
            position="top"
            popover-width="340px"
            :popover-margin="4"
          >
            <span class="font-bold underline">Collection Notice</span>
            <template #content>
              <div>
                <h2 class="text-dark text-base">
                  {{ collectionNotice.title }}
                </h2>
                <!-- eslint-disable vue/no-v-html -->
                <div
                  class="text-dark text-sm font-normal mt-2"
                  v-html="collectionNotice.content"
                ></div>
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </template>
          </atoms-popover-v2>
          and I consent to you contacting me.
        </div>
      </form>
    </div>
    <!-- Success Response -->
    <div v-else-if="formSubmitted && displayFormResponse==='success'">
      <h1 class="mb-4 text-3xl font-bold">
        All done!
      </h1>
      <p>
        <strong>Your booking has been confirmed.</strong><br />
        We have received your request. Our Health Insurance Expert will contact you as per your preferred time and date. We look forward to <strong>talking to you soon.</strong>
      </p>
      <atoms-button
        class="mt-6 lg:px-8 uppercase w-full md:w-auto justify-center"
        size="medium"
        @click="closeEvent"
      >
        Close
      </atoms-button>
    </div>
    <!-- Error Response -->
    <div v-else>
      <h1 class="mb-4 text-3xl font-bold">
        <strong>Oops, something went wrong!</strong>
      </h1>
      <p>
        We’re sorry - We’re experiencing some temporary difficulties.<br />
      </p>
      <p class="mt-4">
        Please try again after some time, or visit our
        <a
          class="font-bold underline"
          :href="'/'+$route.params.vertical+'/schedule-callback/'"
        >Schedule a callback page.</a>
      </p>
      <atoms-button
        class="mt-6 lg:px-8 uppercase w-full md:w-auto justify-center"
        size="medium"
        @click="closeEvent"
      >
        Close
      </atoms-button>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import _isEmpty from 'underscore/cjs/isEmpty.js'
import { STATES } from '~/constants/states.constants'
import { COLLECTION_NOTICE, SCHEDULE_CALLBACK_FORM, SCHEDULE_CALLBACK_ERROR_MAP } from '~/constants/pages/schedule-callback'
import {
  handleScrollToError,
  GENERATE_FORM_MODEL,
  VALIDATE_FORM_ITEM,
  VALIDATE_FORM
} from '~/helpers/formfunnel.helpers.js'

import {
  validateFullname,
  emailValidator,
  phoneNumberValidator,
  stateValidator
} from '~/helpers/validator'

const { devices } = deviceChecker()
const $route = useRoute()
const { $toast, $tracking } = useNuxtApp()

defineOptions({
  name: 'MoleculesScheduleCallback'
})

const props = defineProps({
  vertical: {
    type: String,
    default: 'Health Insurance'
  },
  isStandalonePage: {
    type: Boolean,
    default: false
  },
  showResponse: {
    type: Boolean,
    default: true
  },
  prefillData: {
    type: Object,
    default: () => ({})
  },
  additionalParams: {
    type: Object,
    default: () => ({})
  },
  trackingAdditionalParams: {
    type: Object,
    default: () => ({})
  }
})

const formModel = ref({})
const validation = ref({})
const availableTimings = ref({})

const submitInProgress = ref(false)
const formSubmitted = ref(false)
const displayFormResponse = ref('')

const collectionNotice = COLLECTION_NOTICE
const brazeTrackVerticals = ['Health Insurance']

const utmsStore = useUtmsStore()
// ========================= Begin form handling ========================= //
const formData = SCHEDULE_CALLBACK_FORM
const errorMap = SCHEDULE_CALLBACK_ERROR_MAP

const componentMap = {
  AtomsInput: resolveComponent('AtomsInput'),
  MoleculesSelectDropdown: resolveComponent('MoleculesSelectDropdown'),
  MoleculesSelection: resolveComponent('MoleculesSelection'),
  MoleculesOccupation: resolveComponent('MoleculesOccupation'),
  MoleculesStates: resolveComponent('MoleculesStates')
}

// Initialize the form
function handleModels () {
  formModel.value = handleGenerateModel()

  // Prefill the default data available
  if (!_isEmpty(props.prefillData)) {
    for (const key of Object.keys(props.prefillData)) {
      formModel.value[key] = (props.prefillData[key] !== '' ? props.prefillData[key] : '')
    }
  }
  validation.value = handleGenerateModel('validation')
}

// Generate the form modal from the formData
function handleGenerateModel (type = 'default') {
  const model = GENERATE_FORM_MODEL({
    type,
    form: formData
  })
  return model
}

// Add custom validation map
const validationMap = (key, value) => getKey(key, {
  default: () => !_isEmpty(value),
  full_name: () => validateFullname(value),
  user_email: () => emailValidator(value),
  phone: () => phoneNumberValidator(value),
  state: () => stateValidator(value)
})

// Handle form item specific validations
function handleValidateFormItem (key, value) {
  VALIDATE_FORM_ITEM({
    key,
    value,
    errorMap,
    validation,
    _function: validationMap(key, value)
  })
}

// Handle form validation
function handleValidateForm () {
  VALIDATE_FORM({
    form: formModel,
    validation,
    errorMap,
    externalValidation: ({ value, key }) => {
      const map = getKey(key, {
        full_name: () => handleValidateFormItem(key, value),
        user_email: () => handleValidateFormItem(key, value),
        phone: () => handleValidateFormItem(key, value),
        state: () => handleValidateFormItem(key, value)
      })

      if (map && map?.(key, value)) {
        map(key, value)
      }
    }
  })
}

// Define the date format
const formatDateItem = item => ({ name: dayjs(item.date).format('dddd, MMMM DD'), value: item.date })

const dataArr = computed(() => {
  // Generate the date list
  const dateList = (() => {
    return !_isEmpty(availableTimings.value) ? Object.values(availableTimings.value).map(item => formatDateItem(item)) : []
  })()

  // Generate the time list
  const timeList = (() => {
    if (!formModel.value.date) {
      return []
    }

    let timings = availableTimings.value[formModel.value.date]?.timing

    // Check if it is the current day
    if (!_isEmpty(timings) && dayjs().isSame(dayjs(formModel.value.date), 'day')) {
      let callbackTimings = Object.assign([], timings)
      // Reset the array indexes
      callbackTimings = callbackTimings.filter(item => item !== undefined)
      // Prepend the ASAP option item
      timings = [{ label: 'ASAP', value: 'NOW' }].concat(callbackTimings)
    }
    return timings
  })()

  return {
    state: STATES,
    date: dateList,
    time: timeList
  }
})

onMounted(() => {
  handleModels()

  // Populate field from URL query params
  const fields = { name: 'full_name' }
  Object.keys(fields).forEach(key => {
    if ($route.query[key]) {
      formModel.value[fields[key]] = $route.query[key]
    }
  })
})

// Handle on change of the state param
watch(() => formModel.value.state, async curr => {
  // Reset the date & time
  formModel.value.date = ''
  formModel.value.time = ''
  // Fetch the new availability timings
  if (curr) {
    const { data } = await $fetch('/api/callback/availability', {
      params: {
        state: curr,
        vertical: props.vertical.replace(/\s/g, '')
      }
    })
    availableTimings.value = toRaw(data)
  }
}, { deep: true })

// Handle on change of the date param
watch(() => formModel.value.date, curr => {
  if (curr) {
    // Reset the time
    formModel.value.time = ''
  }
}, { deep: true })

watch(() => props.prefillData, () => {
  handleModels()
})

// ========================= End of form handling ========================= //

const callbackParams = computed(() => {
  const model = formModel.value
  const nameParts = model.full_name?.split(' ') ?? []

  return {
    vertical: props.vertical,
    first_name: nameParts[0] ?? '',
    last_name: nameParts[nameParts.length - 1] ?? '',
    name: model.full_name ?? '',
    phone: model.phone ?? '',
    state: model.state ?? '',
    email: model.user_email ?? '',
    callbackTime: model.date && model.time ? (model.time === 'NOW' ? model.time : `${model.date} ${model.time}`) : '',
    is_callback: true,
    callback_source: 'website'
  }
})

const sendCallbackRequest = async payload => {
  const utms = utmsStore.utms ?? {}
  const additionalParams = props.additionalParams ?? {}
  const params = { ...utms, ...additionalParams, ...payload }
  delete params.id
  delete params.uuid

  const { data } = await $fetch('/api/lead/create', {
    method: 'POST',
    canCancel: true,
    body: params
  })

  if (data?.error) {
    throw data.error
  }

  return data?.lead_uuid ?? undefined
}

const brazeCallbackTrack = async payload => {
  try {
    const phone = payload.phone?.replace('0', '+61')
    const params = {
      phone,
      email: payload.email,
      event: 'schedule_callback',
      properties: { ...payload, phone },
      vertical: props.vertical
    }
    await $fetch('/api/braze/track-user', {
      method: 'POST',
      canCancel: true,
      body: params
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('braze track error: ', err)
  }
}

const handleSubmitForm = async () => {
  await handleValidateForm()
  const hasError = await handleScrollToError(toRaw(validation.value))
  if (hasError) {
    return hasError
  }

  submitInProgress.value = true
  try {
    // Submit the data to the leads API
    const uuid = await sendCallbackRequest(callbackParams.value)
    const model = formModel.value
    const requestTime = _isEmpty(dataArr.value.time)
      ? ''
      : dataArr.value.time.find(item => item.value === model.time)

    if (brazeTrackVerticals.includes(props.vertical)) {
      await brazeCallbackTrack({
        ...callbackParams.value,
        callbackTime: undefined,
        is_callback: undefined,
        requestedCallbackDate: model?.date,
        requestedCallbackTime: requestTime?.label
      })
    }

    if (uuid) {
      $toast.success('Thank you. We will be in touch shortly.')

      const dataLayerParams = (() => {
        const email = (model?.user_email ?? '').toLowerCase()

        return {
          firstName: model?.full_name.split(' ')?.[0] ?? '',
          state: model?.state,
          emailHashed: (email && hash256(email)) || '',
          phoneHashed: (model?.phone && hash256(model.phone)) ?? '',
          requestedCallbackDate: model?.date,
          requestedCallbackTime: requestTime?.label
        }
      })()

      $tracking.customEvent(
        'health_callback_scheduled',
        dataLayerParams
      )

      handleResponse('success')
      resetFormValues()
      handleModels()
    } else {
      handleResponse('error')
      $toast.error('An error occurred while sending your message.')
    }
  } catch (error) {
    handleResponse('error')

    // eslint-disable-next-line no-console
    console.log({ error })
    $toast.error('An error occurred while sending your message.')
  }
}

const emit = defineEmits([
  'submitResponse',
  'close'
])

const handleResponse = status => {
  // Display submit response if needed
  if (props.showResponse) {
    formSubmitted.value = true
    displayFormResponse.value = status
  } else {
    emit('submitResponse', status)
  }
}

function resetFormValues () {
  formModel.value = {
    full_name: '',
    phone: '',
    state: '',
    user_email: '',
    date: '',
    time: ''
  }
  validation.value = {}
}

const clearForm = () => {
  resetFormValues()
  handleModels()
  submitInProgress.value = false
  formSubmitted.value = false
  displayFormResponse.value = ''
}

const closeEvent = () => {
  clearForm()
  emit('close')
}

defineExpose({
  clearForm
})
</script>
